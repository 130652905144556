/**
 * Created by osirvent on 30/06/2016.
 */
'user strict';
angular
    .module('annexaApp')
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('annexa.tram', {
                abstact: true,
                url: '/tram',
                redirectTo: 'annexa.tram.pending',
                views: {
                    "": {
                        controller: 'TramController',
                        templateUrl: './views/layout/tram.html'
                    }
                },
                data: {
                    title: 'global.literals.trams',
                    displayName: 'global.literals.trams',
                    authenticate: true
                },
                resolve: {
                    getDocumentLists: ['DccumentsFactory', function (DccumentsFactory) {
                        return DccumentsFactory.getDocumentLists();
                    }],
                    getTerritorytLists: ['TerritoryFactory', function (TerritoryFactory) {
                        return TerritoryFactory.initializeLists();
                    }]
                }
            })
            .state('annexa.tram.start', {
                url: '/start',
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramStartController',
                        templateUrl: './views/layout/start.html'
                    }
                },
                data: {
                    displayName: 'global.tram.tabs.start.title',
                    permissions: {
                        only: 'start_dossier'
                    }
                }
            })
            .state('annexa.tram.pending',{
                url: '/pending/:type',
                params: {
                	filterId: { value: null, squash:true }
                },
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramPendingController',
                        templateUrl: './views/layout/pending.html'
                    }
                },
                data: {
                    displayName: 'global.tram.tabs.pending.title',
                    permissions: {
                        only: 'process_dossier'
                    }
                }
            })
            .state('annexa.tram.view', {
                url: '/view/:type',
                params: {
                	filterId: { value: null, squash:true }
                },
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramViewController',
                        templateUrl: './views/layout/view.html'
                    }
                },
                data: {
                    displayName: 'global.tram.tabs.view.title',
                    permissions: {
                        only: ['process_dossier', 'view_dossier', 'see_all_dossier']
                    }
                }
            })
            .state('annexa.tram.responsable', {
                url: '/responsable/:type',
                params: {
                	filterId: { value: null, squash:true }
                },
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramResponsableController',
                        templateUrl: './views/layout/responsable.html'
                    }
                },
                data: {
                    displayName: 'global.tram.tabs.responsable.title',
                    permissions: {
                        only: ['process_dossier', 'view_dossier', 'see_all_dossier']
                    }
                }
            })
            .state('annexa.tram.newdossier', {
                url: '/newdossier/:procedure',
                params: {
                    register: undefined,
                    diligence: undefined
                },
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramNewDossierController',
                        templateUrl: './views/layout/newdossier.html'
                    }
                },
                data: {
                    displayName: 'global.tram.new.dossier.title',
                    permissions: {
                        only: 'start_dossier'
                    }
                },
                resolve: {
                    procedure: ['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.procedure) {
                            return TramNewFactory.getProcedure($stateParams.procedure, $rootScope.app.entity, $stateParams.register, $stateParams.diligence);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.pending'); });
                            return $q.reject("No procedure");
                        }
                    }]
                }
            })
			.state('annexa.tram.pending.viewdossier', {
                url: '/viewdossier/:dossier/:dossierTransaction',
                params: {
                    selectedTab: undefined
                },
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramNewViewDossierController',
                        templateUrl: './views/layout/newviewdossier.html'
                    }
                },
                data: {
                    displayName: 'global.tram.view.dossier.title',
                    permissions: {
                        only: 'process_dossier'
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', 'RouteStateFactory', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope, RouteStateFactory) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
							RouteStateFactory.resetRouteState({name:'annexa.tram.pending.viewdossier'});
							return TramNewFactory.getDossier($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.pending'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.archive'); });
                            return $q.reject("No dossier");
                        }
                    }]
                }
            })
            .state('annexa.tram.view.viewdossier', {
                url: '/viewdossier/:dossier',
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramNewViewDossierController',
                        templateUrl: './views/layout/newviewdossier.html'
                    }
                },
                data: {
                    displayName: 'global.tram.view.dossier.title',
                    permissions: {
                        only: ['process_dossier', 'view_dossier', 'see_all_dossier']
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', 'RouteStateFactory', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope, RouteStateFactory) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            RouteStateFactory.resetRouteState({name:'annexa.tram.view.viewdossier'});
							return TramNewFactory.getDossier($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.view'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.view'); });
                            return $q.reject("No dossier");
                        }
                    }]
                }
            })
            .state('annexa.tram.responsable.viewdossier', {
                url: '/viewdossier/:dossier',
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramNewViewDossierController',
                        templateUrl: './views/layout/newviewdossier.html'
                    }
                },
                data: {
                    displayName: 'global.tram.view.dossier.title',
                    permissions: {
                        only: ['process_dossier', 'view_dossier', 'see_all_dossier']
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', 'RouteStateFactory', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope, RouteStateFactory) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            RouteStateFactory.resetRouteState({name:'annexa.tram.responsable.viewdossier'});
							return TramNewFactory.getDossier($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.view'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.view'); });
                            return $q.reject("No dossier");
                        }
                    }]
                }
            })
            .state('annexa.tram.dossierRightAccess', {
                url: '/dossierRightAccess',
                params: {
                	filterId: { value: null, squash:true }
                },
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramDossierRightAccessController',
                        templateUrl: './views/layout/dossierRightAccess.html'
                    }
                },
                data: {
                    displayName: 'global.tram.tabs.dossierRightAccess.title',
                    permissions: {
                        only: ['dossier_right_access']
                    }
                }
            })
            .state('annexa.tram.dossierRightAccess.viewdossier', {
                url: '/viewdossier/:dossier',
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramNewViewDossierController',
                        templateUrl: './views/layout/newviewdossier.html'
                    }
                },
                data: {
                    displayName: 'global.tram.view.dossier.title',
                    permissions: {
                        only: ['dossier_right_access']
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getDossier($stateParams.dossier, true, true);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.view'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.tram.view'); });
                            return $q.reject("No dossier");
                        }
                    }]
                }
            })
            .state('annexa.archive', {
                abstact: true,
                url: '/archive',
                redirectTo: 'annexa.archive.finally',
                views: {
                    "": {
                        controller: 'ArchiveController',
                        templateUrl: './views/layout/archive.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.toptitle',
                    authenticate: true,
                    permissions: {
                        only: ['archive_finally', 'archive_close', 'archive_transfer', 'archive_rejected', 'archive_archive']
                    }
                },
                resolve: {
                    getDocumentLists: ['DccumentsFactory', function (DccumentsFactory) {
                        return DccumentsFactory.getDocumentLists();
                    }],
                    getTerritorytLists: ['TerritoryFactory', function (TerritoryFactory) {
                        return TerritoryFactory.initializeLists();
                    }]
                }
            }).state('annexa.archive.finally', {
                url: '/finally',
                params: {
                	filterId: { value: null, squash:true }
                },
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'ArchiveFinallyController',
                        templateUrl: './views/layout/archiveFinally.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.finally.title',
                    authenticate: true,
                    permissions: {
                        only: 'archive_finally'
                    }
                }
            }).state('annexa.archive.finally.see', {
                url: '/see/:dossier',
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'TramNewViewDossierController',
                        templateUrl: './views/layout/newviewdossier.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.see',
                    authenticate: true,
                    permissions: {
                        only: 'archive_finally'
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getDossier($stateParams.dossier, true);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.finally'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.finally'); });
                            return $q.reject("No dossier");
                        }
                    }]
                }
            }).state('annexa.archive.close', {
                url: '/close',
                params: {
                	filterId: { value: null, squash:true }
                },
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'ArchiveCloseController',
                        templateUrl: './views/layout/archiveClose.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.close.title',
                    authenticate: true,
                    permissions: {
                        only: 'archive_close'
                    }
                }
            }).state('annexa.archive.close.see', {
                url: '/see/:dossier',
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'TramNewViewDossierController',
                        templateUrl: './views/layout/newviewdossier.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.see',
                    authenticate: true,
                    permissions: {
                        only: 'archive_close'
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getDossier($stateParams.dossier, true);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.close'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.close'); });
                            return $q.reject("No dossier");
                        }
                    }]
                }
            }).state('annexa.archive.transfer', {
                url: '/transfer',
                params: {
                	filterId: { value: null, squash:true }
                },
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'ArchiveTransferController',
                        templateUrl: './views/layout/archiveTransfer.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.transfer.title',
                    authenticate: true,
                    permissions: {
                        only: 'archive_transfer'
                    }
                }
            }).state('annexa.archive.transfer.edit', {
                url: '/edit/:dossier',
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'TramNewViewDossierController',
                        templateUrl: './views/layout/newviewdossier.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.edit',
                    authenticate: true,
                    permissions: {
                        only: 'archive_transfer'
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getDossier($stateParams.dossier, true);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.transfer'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.transfer'); });
                            return $q.reject("No dossier");
                        }
                    }]
                }
            }).state('annexa.archive.transfer.edit.document', {
                url: '/document/:document',
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'DocumentViewController',
                        templateUrl: './views/layout/document.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.editDocument',
                    authenticate: true,
                    permissions: {
                        only: 'archive_transfer'
                    }
                },
                resolve: {
                	getDocument: ['DccumentsFactory', '$stateParams', function (DccumentsFactory, $stateParams) {
                		if($stateParams.document) {
                			return DccumentsFactory.getDocument($stateParams.document, true);
                		} else {
                            $timeout(function() { $state.go('annexa.archive.transfer'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                   		return GlobalDataFactory.initializeTaskLists();
                    }]
                }
            }).state('annexa.archive.rejected', {
                url: '/rejected',
                params: {
                	filterId: { value: null, squash:true }
                },
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'ArchiveRejectedController',
                        templateUrl: './views/layout/archiveRejected.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.rejected.title',
                    authenticate: true,
                    permissions: {
                        only: 'archive_rejected'
                    }
                }
            }).state('annexa.archive.rejected.edit', {
                url: '/edit/:dossier',
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'TramNewViewDossierController',
                        templateUrl: './views/layout/newviewdossier.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.edit',
                    authenticate: true,
                    permissions: {
                        only: 'archive_rejected'
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getDossier($stateParams.dossier, true);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.rejected'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.rejected'); });
                            return $q.reject("No dossier");
                        }
                    }]
                }
            }).state('annexa.archive.rejected.edit.document', {
                url: '/document/:document',
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'DocumentViewController',
                        templateUrl: './views/layout/document.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.editDocument',
                    authenticate: true,
                    permissions: {
                        only: 'archive_rejected'
                    }
                },
                resolve: {
                	getDocument: ['DccumentsFactory', '$stateParams', function (DccumentsFactory, $stateParams) {
                		if($stateParams.document) {
                			return DccumentsFactory.getDocument($stateParams.document, true);
                		} else {
                            $timeout(function() { $state.go('annexa.archive.rejected'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                   		return GlobalDataFactory.initializeTaskLists();
                    }]
                }
            }).state('annexa.archive.archive', {
                url: '/archive',
                params: {
                	filterId: { value: null, squash:true }
                },
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'ArchiveArchiveController',
                        templateUrl: './views/layout/archiveArchive.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.archive.title',
                    authenticate: true,
                    permissions: {
                        only: 'archive_archive'
                    }
                }
            }).state('annexa.archive.archive.edit', {
                url: '/edit/:dossier',
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'TramNewViewDossierController',
                        templateUrl: './views/layout/newviewdossier.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.edit',
                    authenticate: true,
                    permissions: {
                        only: 'archive_archive'
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getDossier($stateParams.dossier, true);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.archive'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.archive'); });
                            return $q.reject("No dossier");
                        }
                    }]
                }
            }).state('annexa.archive.archive.edit.document', {
                url: '/document/:document',
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'DocumentViewController',
                        templateUrl: './views/layout/document.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.editDocument',
                    authenticate: true,
                    permissions: {
                        only: 'archive_archive'
                    }
                },
                resolve: {
                	getDocument: ['DccumentsFactory', '$stateParams', function (DccumentsFactory, $stateParams) {
                		if($stateParams.document) {
                			return DccumentsFactory.getDocument($stateParams.document, true);
                		} else {
                            $timeout(function() { $state.go('annexa.archive.archive'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                   		return GlobalDataFactory.initializeTaskLists();
                    }]
                }
            }).state('annexa.archive.archive.see', {
                url: '/see/:dossier',
                views: {
                    "mainarchive@annexa.archive": {
                        controller: 'TramNewViewDossierController',
                        templateUrl: './views/layout/newviewdossier.html'
                    }
                },
                data: {
                    title: 'global.archive.toptitle',
                    displayName: 'global.archive.see',
                    authenticate: true,
                    permissions: {
                        only: 'archive_archive'
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getDossier($stateParams.dossier, true);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.archive'); });
                            return $q.reject("No dossier");
                        }
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
                    tramtemplates:['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.dossier) {
                            $rootScope.showLoadingdivSignin = true;
                            return TramNewFactory.getTramTemplates($stateParams.dossier);
                        } else {
                            $timeout(function() { $state.go('annexa.archive.archive'); });
                            return $q.reject("No dossier");
                        }
                    }]
                }
            }).state('annexa.tram.byNumber', {
                url: '/byNumber/:number',
                views: {
                    "maintram@annexa.tram": {
                        controller: 'TramByNumberController',
                        templateUrl: './views/layout/tram.html'
                    }
                },
                data: {
                    displayName: 'global.tram.view.dossier.title',
                    authenticate: true,
	                permissions: {
                        only: ['process_dossier', 'view_dossier', 'see_all_dossier']
                    }
                },
                resolve: {
                    viewdossier: ['$q', '$state', '$stateParams', 'TramNewFactory', '$timeout', '$rootScope', function($q, $state, $stateParams, TramNewFactory, $timeout, $rootScope) {
                        if($stateParams.number) {
	                        $rootScope.showLoadingdivSignin = true;
	                        return TramNewFactory.getDossierByNumber($stateParams.number);
	                    } else {
	                        $timeout(function() { $state.go('annexa.dashboard'); });
	                        return $q.reject("No dossier");
	                    }
                    }]
                }
            });
    }]);